import { ACCOUNT_UPDATE, CONTRACT_LOADED, DATA_LOADED, RATE, SEAPORT } from "../constants/action-types";



const initialState = {
  account: null,
  blockchainClient: null,
  mydata:null
};

function rootReducer(state = initialState, action) {
  console.log(action,"action")

  switch (action.type) {
    case ACCOUNT_UPDATE:
      return Object.assign({}, state, {
        account: action.payload
      });
    case CONTRACT_LOADED:
      return Object.assign({}, state, {
        contract: action.payload
      });
    case DATA_LOADED:
      return Object.assign({}, state, {
        mydata: action.payload
      });
    case RATE:
      return Object.assign({}, state, {
        rate: action.payload
      });
  }



  console.log(state,"action")
  return state;
};

export default rootReducer;