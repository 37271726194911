import React, { useEffect, useState } from 'react'
import Web3 from 'web3';
import config from './config';
import { accountUpdate, contractLoaded, dataLoaded, ethtodollar } from '../redux/actions';
import { connect, useSelector } from 'react-redux';
import nftAbi from './ABi/nftAbi';



function BlockchainProvider(props) {
    const [account, setaccount] = useState("");
    useEffect(() => {
        console.log(props, "stake props")
        //const contractAddr = Config.CONTRACT_ADDRESS
        const web3 = new Web3(Web3.givenProvider);
        try {
            accountdetail(web3)
            // console.log(accountsPassed, "accountPasse");
            const nftContract = new web3.eth.Contract(nftAbi, config.nftContract);
            props.dispatch(contractLoaded(nftContract))
            setInterval(() => {

                initilizeData(nftContract);

            }, 5000);
            console.log(nftContract, "contracts");


        }
        catch (err) {
            console.log(err)
        }
        return () => { }
    }, [])

    async function accountdetail(web3) {
        const accounts = await web3.eth.getAccounts()
        console.log(accounts, "accounts")
        setaccount(accounts[0]);

        //console.log(accounts[0],"accounts")
        props.dispatch(accountUpdate(accounts[0]))

    }



    const initilizeData = async (nftContract) => {



        let price = await nftContract.methods.price().call();

        let localData = {
            price
        }

        props.dispatch(dataLoaded(localData))



    }


    // const initilizeData = async (nftContract, stakingContract) => {
    //     let stakeddata = []
    //     let isApproved = false;

    //     let stakedArray =[]

    //     let pendingRewards = 0;
    //     console.log(props.account, stakingContract, "stake")
    //     if (props.account) {

    //         isApproved = await nftContract.methods.isApprovedForAll(props.account, config.stakingContract).call();

    //         stakedArray = await stakingContract.methods.getMyStakingInfo(props.account).call();

    //         stakedArray.map((e) => {
    //             if(e.nftId != "0"){
    //                 stakeddata.push(e);
    //             }
    //         })

    //         pendingRewards = await stakingContract.methods.getPendingReward(props.account).call()/10**18
    //         console.log(stakeddata, "stake data");
    //         let localdata = {

    //             isApproved,
    //             stakeddata,
    //             pendingRewards
    //         }

    //         props.dispatch(dataLoaded(localdata))

    //     }


    // }


    return (
        <div>

        </div>
    )
}
const mapStateToProps = function (state) {
    return {
    }
}

export default connect(mapStateToProps)(BlockchainProvider)
