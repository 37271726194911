import React, { useState, useEffect, useRef } from 'react'
import './NavBar.css';
import logo from './logoTextLight.png';
import mlogo from './9.png';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import BtnImg from './button.svg'
import { Burger, Menu } from '../components';
import FocusLock from 'react-focus-lock';
import { Link, animateScroll as scroll } from "react-scroll";
import { useWallet } from '@binance-chain/bsc-use-wallet';

const H = styled.h1`
  margin-left: 1rem;
  font-size: 3rem;
  @media only screen and (max-width: 1450px) {
    font-size: 2rem;
  }
  @media only screen and (max-width: 1150px) {
    margin:1rem  0 0 0;
  }
  @media only screen and (max-width: 768px) {
    font-size: 1rem;
    margin: 0;
  }
`
const Link1 = styled(Link)`
    display: flex;
    margin: 0 2rem;
    align-items: center;
    text-decoration: none;
    color: #FFF;
    cursor: pointer;

    -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        -webkit-transition-duration: 0.5s;
        transition-duration: 0.5s;

     &:hover, &:focus,&:active {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
      }
`;

export const Link2 = styled(Link)`
    display: flex;
    margin: 0 0.5rem;
    align-items: center;
    text-decoration: none;
    color: #FFF;
    cursor: pointer;
    @media only screen and (max-width: 1450px) {
      font-size: 1rem;
 }
    @media only screen and (max-width: 1025px) {
      margin: 0 ;
 }
    @media only screen and (max-width: 768px) {
         margin: 0 1rem;
    }
`;

const Link2A = styled.a`
    display: flex;
    margin: 0 0.5rem;
    align-items: center;
    text-decoration: none;
    color: #FFF;
    cursor: pointer;
    @media only screen and (max-width: 1450px) {
      font-size: 1rem;
 }
    @media only screen and (max-width: 1025px) {
      margin: 0 ;
 }
    @media only screen and (max-width: 768px) {
         margin: 0 1rem;
    }
`;

const ButtonBack = styled.div`
    background-color: #deba27;
    height: 3.4rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    border-radius: 0.7rem;
    width: auto;
    @media only screen and (max-width: 768px) {
      height: 3rem;
 }
`

const Button = styled(motion.div)`
    background-color: #deba27;
    color:#013c8a;
    height: 3rem;
    border: none;
    border-bottom: 2px solid #E5E5E5;
    border-radius: 0.7rem;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0 1rem;
    display: grid;
    place-items: center;
    padding-top: 0.5rem; 
    width: auto;
    @media only screen and (max-width: 768px) {
      height: 2.7rem;
   
 }
`;

const Mobile = styled.div`
    display: none;
  @media only screen and (max-width: 998px) {
    display: flex;
  }`

const PC = styled.div`
  display: block;
@media only screen and (max-width: 998px) {
  display: none;
}`

// const H = styled.h2`
//     color: #FFF;
//     font-weight: bolder;
//     font-size: 3rem;
//     @media only screen and (max-width: 768px) {
//         font-weight: 550;
//         font-size: 1.2rem;
//         text-align: center;
//       }
// `;

const NavBar = () => {

  const [open, setOpen] = useState(false);
  const node = useRef();
  const menuId = "main-menu";


  const { account, connect } = useWallet();
  const [navbar, setNavbar] = useState(false)

  const changeBackground = () => {
    console.log(window.scrollY)
    if (window.scrollY >= 100) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  useEffect(() => {
    changeBackground()
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground)
  })

  return (
    <nav className={navbar ? "navActive" : "nav"} >

      <Link1 to="home" spy={true} smooth={true}  >
        {/* <img src={logo} alt="Logo" className="Logo" /> */}
        <H>
          Oasis Gorillas
        </H>
      </Link1>

      {/* <Mobile>
        <Link1 to="home" spy={true} smooth={true} style={{ marginRight: "0" }}  ><img src={mlogo} alt="mlogo" className="LogoB" /></Link1>
      </Mobile> */}
      <PC>
        <ul className="list">
          <Link2 to="home" spy={true} smooth={true} ><li>Home</li></Link2>
          <Link2 to="roadmap" spy={true} smooth={true}><li>Roadmap</li></Link2>
          <Link2 to="faq" spy={true} smooth={true} offset={-80} ><li>FAQ</li></Link2>
          <Link2A href='https://rimswap.app/farms' target='_blank'><li>Farms</li></Link2A>
          <Link2A href='https://dex.rimswap.app/#/swap' target='_blank'><li>Swap</li></Link2A>
          {/* <Link2 to="roadmap" spy={true} smooth={true}><li>Twitter</li></Link2>
          <Link2 to="roadmap" spy={true} smooth={true}><li>Telegram</li></Link2>
          <Link2 to="roadmap" spy={true} smooth={true}><li>Discord</li></Link2> */}
          <ButtonBack>
            <Button
            // onClick={() => { wallet.connect() }}
            >
              {account ? account.slice(0, 10) : "Connect Wallet"}
            </Button>
          </ButtonBack>

        </ul>
      </PC>

      <Mobile ref={node} >
        <ButtonBack
          style={{ marginRight: "5rem" }}
        >
          <Button>

            {account ? account.slice(0, 10) : "Connect"}
          </Button>
        </ButtonBack>
        <FocusLock disabled={!open}>
          <Burger open={open} setOpen={setOpen} aria-controls={menuId} />
          <Menu open={open} setOpen={setOpen} id={menuId} />
        </FocusLock>
      </Mobile>

      {/* <Mobile ref={node}>
            <FocusLock disabled={!open}>
              <Burger open={open} setOpen={setOpen} aria-controls={menuId} />
              <Menu open={open} setOpen={setOpen} id={menuId} />
            </FocusLock>
          </Mobile> */}
      {/* <StyledBurger  onClick={()=> setIsMobile(!isMobile)} >
                <div className="Line1" onClick={()=> isButton(!button)} ></div>
                <div className="Line2"  onClick={()=> isButton(!button)}></div>
                <div className="Line3" onClick={()=> isButton(!button)}></div>
            </StyledBurger> */}
    </nav>
  )
}

export default NavBar
