import React from 'react'
import styled from 'styled-components'
import blue from '../../page/config/blue.svg'
import Tilt from 'react-parallax-tilt';
import { motion } from 'framer-motion';

const H = styled.h2`
    color: #FFF    ;
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    z-index: 2;
    @media only screen and (max-width: 768px) {
        font-weight: bold;
        font-size: 1.2rem;
      }
`;

const T = styled.p`
    color: #FFF    ;
    font-weight: 500;
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
    z-index: 2;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1rem;
      }
`;


const DefaultCard2 = (props) => {

    const Box = styled(motion.div)`
        height: 18rem;
        width: 99%;
        display : flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin: 2rem 1rem;
        
    `;

    const Upper = styled.div`
        height: 50%;
        width: 100%;
        border-radius: 8px;`;

    const Lower = styled.div`
        height: 60%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding-left: 0.5rem;
        `;


    return (
        // <Tilt>
            <Box>
                <Upper>
                    <img src={`${props.img? props.img : ""}`} alt="" style={{width:'6rem',height:'6rem',borderRadius:'50%'}}/>
                </Upper>
                <Lower>
                    <H>{`${props.name? props.name : "DOT"}`}</H>
                    <T>{`${props.L1? props.L1 : "An uncommon Polymon in the Polyverse."}`}</T>
                    <T>{`${props.L2? props.L2 : "An uncommon Polymon in the Polyverse."}`}</T>
                    <T>{`${props.L3? props.L3 : ""}`}</T>
                    <T>{`${props.L4? props.L4 : ""}`}</T>
                </Lower>
            {/* <H>{`${props.name? props.name : "DOT"}`}</H>
            <T>{`${props.desc? props.desc : "An uncommon Polymon in the Polyverse."}`}</T> */}
            </Box>
        // </Tilt>
    )
}

export default DefaultCard2
