import React, { useState } from 'react'
import styled from 'styled-components'
// import b1 from './1.png'
// import b2 from './2.png'
import Tilt from 'react-parallax-tilt';
import { FaAngleDown } from 'react-icons/fa';
import './Faq.css'
import { MdOutlineAdd } from 'react-icons/md'

const Sec = styled.section`
     display: grid;
     place-items: center;
    min-height: 100vh;
    width: 100%;
    background: linear-gradient(to right, #c31432, #240b36);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 0 10% 4rem 5%;
    @media only screen and (max-width: 768px) {
        min-height: 60rem;
        width: 100%;
        padding: 1rem 0.5rem 2rem 0.5rem;
        flex-direction: column;
        background-size: auto 100%;
    }
`

const Row = styled.div`
      width: 80%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      margin: 1rem 2rem 1rem 2rem;
      padding: 2rem 2rem 0 2rem;
      background-color: #404040;
      border-radius: 2rem;
      @media only screen and (max-width: 768px) {
        width: 95%;
        padding: 2rem 1rem 0 1rem;
        margin: 1rem 0;
        border-radius: 1.5rem;
      }
`;

const H = styled.h1`
    color: #ff0000;
    font-size: 1.5rem;
    font-weight: bold;

    text-transform: uppercase;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1.2rem;
        font-weight: bold;
        text-align: center;
        margin-bottom: 1rem;
      }
`;

const Ht = styled.h1`
    color: #E5E5E5;
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
    width: 80%;
    font-family: 'Montserrat', sans-serif ;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1.5rem;
        font-weight: bold;
      }
`;

const T = styled.p`
    color: #E5E5E5;
    font-weight: 500;
    font-size: 1.6rem;
    
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1rem;
       
      }
`;

const Container = styled.div`
      width: 40%;
      display: grid;
      place-items: center;
    //   background-color: #404040;
      border-radius: 2rem;
      padding: 2rem 0;
      @media only screen and (max-width: 1100px) {
        width: 95%;
        margin-top: 2rem; 
        padding: 2rem 0;
      }
      @media only screen and (max-width: 768px) {
        width: 95%;
        margin-top: 2rem; 
        padding: 2rem 0;
      }
`;

const Img = styled.div`
    width: 20rem;
    height: 15rem;
    @media only screen and (max-width: 768px) {
        width: 95%;
        height: auto;
      }
`;


const Row2 = styled.div`
    display: flex;
    flex-direction: row;
    width: 80%;
    justify-content: space-around;
    margin-top: 2rem;
    @media only screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: 0rem;
    }
`


const Box = styled.div`
    color: #FFFFFF;
    background:linear-gradient(to right, #200122, #6f0000) ;
    width: 1300px;
    font-size: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 1rem;
    justify-content: space-between;
    padding: 0.5rem 1.5rem;
    margin: 1rem 0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    @media only screen and (max-width: 1500px) {
      width: 95%;
    }
    @media only screen and (max-width: 768px) {
        width: 98%;
        padding: 0.5rem;
      }
`;

const Content = styled.div`
      //  background:linear-gradient(to top, #0f2027, #203a43, #2c5364)   ;
       width: 100%;
       padding: 1rem 2rem ;
       font-size: 1.6rem;
       color: #E5E5E5;
       @media only screen and (max-width: 768px) {
        padding: 1rem;
        font-size: 1rem;
      }
`
const Upper = styled.div`
display: flex;
align-items: center;
      border-radius: 1rem;
    justify-content: space-between;
    padding: 0.5rem 1.5rem;
    width: 100%;
`


const Faq = () => {

    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [open5, setOpen5] = useState(false);
    const [open6, setOpen6] = useState(false);


    return (
        <Sec id='faq'>
            <Ht>F.A.Qs</Ht>
            <Box>

                <Upper>
                    <T>Who is behind OasisGorillas?</T>
                    <H><MdOutlineAdd color="#FFF" onClick={() => open1 ? setOpen1(false) : setOpen1(true)} style={{ cursor: "pointer" }} /></H>
                </Upper>

                <Content className={open1 ? "show" : "hide"} >
                    The OasisGorillas NFT was developed by the <a href='https://rimswap.app/' target='_blank' style={{ color: 'red', textDecoration: 'none' }}>RIMSwap</a>  AMA team. RIMSwap is one of the first automated market-making (AMM), decentralized exchange (DEX) built on the Oasis Emerald Network.
                </Content>

            </Box>
            <Box>

                <Upper>
                    <T>Mint cost for OasisGorillas?</T>
                    <H><MdOutlineAdd color="#FFF" onClick={() => open2 ? setOpen2(false) : setOpen2(true)} style={{ cursor: "pointer" }} /></H>
                </Upper>

                <Content className={open2 ? "show" : "hide"} >

                    The estimated presale mint price will be 300 ROSE, and 400 ROSE for the public sale.
                </Content>

            </Box>
            <Box>

                <Upper>
                    <T>Benefits of holding OasisGorillas NFT?</T>
                    <H><MdOutlineAdd color="#FFF" onClick={() => open3 ? setOpen3(false) : setOpen3(true)} style={{ cursor: "pointer" }} /></H>
                </Upper>

                <Content className={open3 ? "show" : "hide"} >
                    By holding OasisGorillas NFT you will get a free airdrop of RIM tokens every week (RIM token is the Native token of RIMSwap DEX). Every week, 30% of the AMA trading fee will be distributed to OasisGorilla NFT holders. Mint your OasisGorillas to earn passive income every week.
                    <div style={{ margin: '1rem 0 0 0' }} />
                    Also, You can earn by trading OasisGorillas at a higher price.
                </Content>

            </Box>

            <Box>

                <Upper>
                    <T>How many OasisGorillas will ever exist?</T>
                    <H><MdOutlineAdd color="#FFF" onClick={() => open4 ? setOpen4(false) : setOpen4(true)} style={{ cursor: "pointer" }} /></H>
                </Upper>

                <Content className={open4 ? "show" : "hide"} >
                    There are only 10,000 OasisGorillas to ever exist.
                </Content>

            </Box>
            <Box>

                <Upper>
                    <T>Will OasisGorillas be listed on a marketplace?</T>
                    <H><MdOutlineAdd color="#FFF" onClick={() => open5 ? setOpen5(false) : setOpen5(true)} style={{ cursor: "pointer" }} /></H>
                </Upper>

                <Content className={open5 ? "show" : "hide"} >
                    Of course, OasisGorillas will be listed on a large marketplace. Also, we will launch our own marketplace.
                </Content>

            </Box>
            <Box>

                <Upper>
                    <T>OasisGorillas contract address?</T>
                    <H><MdOutlineAdd color="#FFF" onClick={() => open6 ? setOpen6(false) : setOpen6(true)} style={{ cursor: "pointer" }} /></H>
                </Upper>

                <Content className={open6 ? "show" : "hide"} >
                    0x37643c0F2a9E64957559afCDf5fE509EA0ad626f
                </Content>

            </Box>
            {/*
             <Box>

            <Upper>
            <T>Traditionally, a text is understood to be a piece</T>
            <H><FaAngleDown color="#FFF" onClick={ () => open ? setOpen(false) : setOpen(true)} style={{cursor:"pointer"}} /></H>
            </Upper>

            <Content className={ open ? "show" : "hide" } >
            The Public Sale starts on November 1st, 2021, at 9 PM EST. Price Starts at 0.3 ETH + gas for 6 hours. If the collection is not sold out by then, price drops to 0.25 ETH + gas after the initial 6 hours. If the collection is still not sold out after those 12 hours, the rest of the Fat Apes are sold at 0.2 ETH + gas.
            </Content>

    </Box> */}
            {/* <Row>
                <H >Who is behind OasisGorillas?</H>
                <T>The OasisGorillas NFT was developed by the RIMSwap AMA team. RIMSwap is one of the first automated market-making (AMM), 
                    decentralized exchange (DEX) built on the Oasis Emerald Network.</T>
                <H >Mint cost for OasisGorillas?</H>
                <T>The estimated presale mint price will be 300 ROSE, and 400 ROSE for the public sale.</T>
            </Row>
            <Row>
                <H>Benefits of holding OasisGorillas NFT?</H>
                <T>By holding OasisGorillas NFT you will get a free airdrop of RIM tokens every week (RIM token is the Native token of RIMSwap DEX). Every week, 30% of the AMA trading fee will be distributed to OasisGorilla NFT holders.
                     Mint your OasisGorillas to earn passive income every week. </T>
                <T>Also, You can earn by trading OasisGorillas at a higher price.</T>
            </Row>
            <Row>
                <H>Everyone will get an equal amount of “RIM tokens” Airdrop?</H>
                <T>No, the one with more NFTs will receive a larger airdrop. So mint or buy more OasisGorilla NFTs to earn more airdrops. Every week, 30% of the AMA trading fee will be distributed to OasisGorilla NFT holders.</T>
            </Row>
            <Row>
                <H>How many OasisGorillas will ever exist?</H>
                <T>There are only 10,000 OasisGorillas to ever exist.</T>
            </Row>
            <Row>
                <H>Will OasisGorillas be listed on a marketplace?</H>
                <T>Of course, OasisGorillas will be listed on a large marketplace. Also, we will launch our own marketplace.</T>
            </Row>
            <Row2>
                <Container>
                    <H style={{textAlign:"center"}}>Will owning a Rock Pal get me laid??</H>
                    <Img>
                        <img src={b1} alt="" style={{width:"100%",height:"100%",borderRadius: "0.5rem"}} />
                    </Img>
                </Container>
                <Container>
                    <Hl style={{textAlign:"center"}}>WHY ON EARTH WOULD I BUY A JPEG OF A ROCK?</Hl>
                    <Img>
                        <img src={b2} alt="" style={{width:"100%",height:"100%",borderRadius: "0.5rem"}} />
                    </Img>
                </Container>
            </Row2> */}
            {/* <T style={{color:" #E5E5E5",textTransform:"capitalize", margin:"4rem 0 0 0 "}} >In all seriousness here’s our <span style={{color:"#D86868",textDecoration:"underline",cursor:"pointer"}} ><a href="/faq">real F.A.Qs</a></span></T> */}
        </Sec>
    )
}

export default Faq
