import React from 'react'
import Card from '../../components/Card/Card'
import styled from 'styled-components';
import bg from './bg3.png'
import {data} from '../config/config'

const Page = styled.section`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-image: url(${bg});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    @media only screen and (max-width: 768px) {
        min-height: 115rem;
        padding: 2rem 0.5rem;
        background-size: auto 100%;
    }
`;

const Grid = styled.div`
    display: grid;
    width: 100%;
    height: auto;
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    place-items: center;
    @media only screen and (max-width: 768px) {
        grid-template-columns: repeat(auto-fit, minmax(90%, 1fr));
    }
`;

const Page3 = () => {
    return (
        <Page>
            <Grid>
            {data?.map(e => (
                <Card 
                    bg={e.img}
                    name={e.name}
                    desc={e.desc}
                /> 
            ))}
             </Grid>
        </Page>
    )
}

export default Page3
