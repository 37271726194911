import img1 from './twitter.jpg'
import img2 from './telegram.webp'
import img3 from './discord.jpg'
import img4 from './frams.jpg'
import img5 from './1.svg'
import imgd1 from './ff.jpeg'
import imgd2 from './2.svg'
import imgd3 from './3.svg'
import imgd4 from './airdrop.png'

export const data = [

    {img:img1,name:"Twitter",link:"https://twitter.com/RIMSwap",L2:"No Price Tiers",L3:"Open to Everyone",details:"Bitic, the thunder dragon Polymon, holds domain over a large area of land. The land is mostly mountainous, and few other Polymon and humans live there. This is just as Bitic wants, as it prefers its own solitude. Bitic gathers enormous amounts of energy inside it simply by existing. Often, this energy needs to be released. Bitic releases it by creating large thunder and lightning explosions with it at the center. Those explosions do not harm Bitic, but make craters out of the nearby land. This is why Bitic prefers solitude, as anything near the explosion faces an early death.", rank:"01", isSale:true,main: false},
    {img:img2,name:"Telegram",link:"https://t.me/Rimswap_chat/",L2:"Stored on IFPS as",L3:"ERC-721 Tokens", details:"Ethe, the legendary star steel dragon Polymon, is always roaming the world looking for powerful adversaries. Like Lygon, Ethe lives high up in the sky, beyond the atmosphere. Also like Lygon, it often comes descends. However, where Lygon comes down into the world to give gifts on specific days, Ethe only appears to affirm its supremacy. From high up beyond the atmosphere, Ethe is still able to see what is going on in the world. The moment it sees another Polymon boast of its strength, Ethe descends and challenges the other Polymon to a fight.",rbtn:"DARK",rbg:"#333",rank:"02", isSale:false,main: false},
    {img:img3,name:"Discord",link:"https://discord.gg/uXWSuCdQcr",L2:"Commercial Rights",L3:"Verified on ETH",L4:"",details:"Carda is a legendary Polymon. It lives in the bottom of the deepest seas, and, as such, not many people have seen it. Carda acts as a protector of the deep seas, keeping watch both for human intrusions and also for other Polymon wishing to enter the area. If the need arises, Carda is more than capable of defending its territory and the Polymon it considers to be its wards.Carda’s more terrifying feature is its roar. Akin to a lion’s roar but magnified tenfold, it is often enough to cause other Polymon to flee without a fight.",rbtn:"WATER",rbg:"#0034af",rank:"03", isSale:false,main: false},
    {img:img4,name:"Farm",link:"https://rimswap.app/farms",L2:"Alzheimer's Research ",L3:"and Stroke Recovery", details:"Lygon is the legendary celestial Polygon. Although it lives high up in the sky, beyond the atmosphere, its existence is well recorded.This is because Lygon often appears to others. In particular, it always appears on set dates during the year, at times when specific stars are visible together in the sky. Those times are well known to people. Lygon appears in a different location each time, but the locations where it might appear are always five specific areas. Great festivals are held there on the days where Lygon might appear. ",rbtn:"POISON",rbg:"#8b44c9",rank:"04", isSale:true,main: false},
    {img:img5,name:"Swap",link:"https://dex.rimswap.app/",L2:"Alzheimer's Research ",L3:"and Stroke Recovery", details:"Lygon is the legendary celestial Polygon. Although it lives high up in the sky, beyond the atmosphere, its existence is well recorded.This is because Lygon often appears to others. In particular, it always appears on set dates during the year, at times when specific stars are visible together in the sky. Those times are well known to people. Lygon appears in a different location each time, but the locations where it might appear are always five specific areas. Great festivals are held there on the days where Lygon might appear. ",rbtn:"POISON",rbg:"#8b44c9",rank:"04", isSale:true,main: false},
]

export const defaultdata = [

    {img:imgd1,name:"Fair Distribution",L1:"Presale cost: 300 ROSE",L2:"Public sale cost: 400 ROSE",L3:"Open to Everyone",details:"Bitic, the thunder dragon Polymon, holds domain over a large area of land. The land is mostly mountainous, and few other Polymon and humans live there. This is just as Bitic wants, as it prefers its own solitude. Bitic gathers enormous amounts of energy inside it simply by existing. Often, this energy needs to be released. Bitic releases it by creating large thunder and lightning explosions with it at the center. Those explosions do not harm Bitic, but make craters out of the nearby land. This is why Bitic prefers solitude, as anything near the explosion faces an early death.", rank:"01", isSale:true,main: false},
    {img:imgd2,name:"Randomly Generated",L1:"Over 200 Unique Elements",L2:"Stored on IFPS as",L3:"ERC-721 Tokens", details:"Ethe, the legendary star steel dragon Polymon, is always roaming the world looking for powerful adversaries. Like Lygon, Ethe lives high up in the sky, beyond the atmosphere. Also like Lygon, it often comes descends. However, where Lygon comes down into the world to give gifts on specific days, Ethe only appears to affirm its supremacy. From high up beyond the atmosphere, Ethe is still able to see what is going on in the world. The moment it sees another Polymon boast of its strength, Ethe descends and challenges the other Polymon to a fight.",rbtn:"DARK",rbg:"#333",rank:"02", isSale:false,main: false},
    {img:imgd3,name:"Ownership",L1:"Random Minting",L2:"Commercial Rights",L3:"Verified on Oasis",L4:"",details:"Carda is a legendary Polymon. It lives in the bottom of the deepest seas, and, as such, not many people have seen it. Carda acts as a protector of the deep seas, keeping watch both for human intrusions and also for other Polymon wishing to enter the area. If the need arises, Carda is more than capable of defending its territory and the Polymon it considers to be its wards.Carda’s more terrifying feature is its roar. Akin to a lion’s roar but magnified tenfold, it is often enough to cause other Polymon to flee without a fight.",rbtn:"WATER",rbg:"#0034af",rank:"03", isSale:false,main: false},
    {img:imgd4,name:"Air Drop",L1:"Every week 30% of the",L2:"RIMSWAP AMA trading fee",L3:"will be Airdropped to", L4:"OasisGorilla NFT user",details:"Lygon is the legendary celestial Polygon. Although it lives high up in the sky, beyond the atmosphere, its existence is well recorded.This is because Lygon often appears to others. In particular, it always appears on set dates during the year, at times when specific stars are visible together in the sky. Those times are well known to people. Lygon appears in a different location each time, but the locations where it might appear are always five specific areas. Great festivals are held there on the days where Lygon might appear. ",rbtn:"POISON",rbg:"#8b44c9",rank:"04", isSale:true,main: false},
]




