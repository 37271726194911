import React,{ useState } from 'react'
import './Css.css';
import styled from 'styled-components'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { SiHomeassistant } from 'react-icons/si';
import { IoIosPodium } from 'react-icons/io';
import { SiAzuredataexplorer } from 'react-icons/si';
import { SiZig } from 'react-icons/si'
import { HiFingerPrint } from 'react-icons/hi'
import logo from './logo.png'

const Sec = styled.section`
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 8rem  0;
     background: linear-gradient(to right, #c31432, #240b36) ;
    background-repeat: no-repeat;



    @media only screen and (max-width: 1210px) {
        padding: 12rem 0 8rem 0;
      }
  
    @media only screen and (max-width: 768px) {
        width: 100%;
        padding: 5rem 0;
        flex-direction: column;
        justify-content: space-around;
      }
`;

const T = styled.p`
    color: #FFFFFF;
    font-weight: 500;
    font-size: 1.3rem;
    
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1rem;
      }
`;

const Ts = styled.p`
    color: ;
    font-weight: 500;
    font-size: 1.3rem;
    margin: 0 0 2rem 0; 
    padding: 0 2rem;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1rem;
        padding: 0 1rem;
        
      }
`;

const H = styled.h2`
    color: #FFF;
    font-weight: bolder;
    font-size: 3rem;
    @media only screen and (max-width: 768px) {
        font-weight: 550;
        font-size: 1.2rem;
        text-align: center;
      }
`;


const Icon = styled.div`
      width: 3.6rem;
      height: 3.4rem;
     margin: 0.15rem 0 0 0.15rem;
`

const Roadmap2 = () => {
  
    return (
        <Sec id="roadmap">
            <div>
                <H style={{textAlign:"center"}}>ROADMAP</H>
                {/* <Ts>Once a milestone is hit, we will begin working on bringing that goal to fruition.</Ts> */}
            </div>
            <VerticalTimeline>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'linear-gradient(to left,#c31432,#240b36)', color: '#FFF' }}
                    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                    // date="Phase 01"
                    iconStyle={{ background: '#000', color: '#fff' }}
                    icon={<Icon>
                        <img src={logo} alt="" style={{height:"100%",width:"100%"}}/>
                    </Icon>}
                >
                    {/* <h3 className="vertical-timeline-element-title">0%</h3> */}
                    <h4 style={{fontSize:"1.4rem"}} className="vertical-timeline-element-subtitle">Milestone 1</h4>
                    <T>
                    UX/UI Design and Development
                    </T>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'linear-gradient(to left,#c31432,#240b36)', color: '#FFF' }}
                    // date="Phase 02"
                    iconStyle={{ background: '#000000', color: '#fff' }}
                    icon={<HiFingerPrint />}
                >
                    <h3 className="vertical-timeline-element-title"></h3>
                    <h4 style={{fontSize:"1.4rem"}} className="vertical-timeline-element-subtitle">Milestone 2</h4>
                    <T>
                    Development of 10,000 unique OasisGorillas art.

                    </T>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'linear-gradient(to left,#c31432,#240b36)', color: '#FFF' }}
                    // date="Phase 04"
                    iconStyle={{ background: '#000000', color: '#fff' }}
                    icon={<HiFingerPrint />}
                >
                    <h3 className="vertical-timeline-element-title"></h3>
                    <h4 style={{fontSize:"1.4rem"}} className="vertical-timeline-element-subtitle">Milestone 3</h4>
                    <T>
                    Development of Core smart contracts

                    </T>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'linear-gradient(to left,#c31432,#240b36)', color: '#FFF' }}
                    // date="Phase 03"
                    iconStyle={{ background: '#000000', color: '#fff' }}
                    icon={<HiFingerPrint />}
                >
                    <h3 className="vertical-timeline-element-title"></h3>
                    <h4 style={{fontSize:"1.4rem"}} className="vertical-timeline-element-subtitle">Milestone 4</h4>
                    <T>
                    Establishing Social Media for our community to interact with each other. 

                    </T>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'linear-gradient(to left,#c31432,#240b36)', color: '#FFF' }}
                    // date="Phase 05"
                    iconStyle={{ background: '#000000', color: '#fff' }}
                    icon={<HiFingerPrint />}
                >
                    <h3 className="vertical-timeline-element-title"></h3>
                    <h4 style={{fontSize:"1.4rem"}} className="vertical-timeline-element-subtitle">Milestone 5</h4>
                    <T>
                    Marketing 
                    </T>
                </VerticalTimelineElement>
            
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'linear-gradient(to left,#c31432,#240b36)', color: '#FFF' }}
                    // date="Phase 05"
                    iconStyle={{ background: '#000000', color: '#fff' }}
                    icon={<HiFingerPrint />}
                >
                    <h3 className="vertical-timeline-element-title"></h3>
                    <h4 style={{fontSize:"1.4rem"}} className="vertical-timeline-element-subtitle">Milestone 6</h4>
                    <T>
                    Presale: Presale will run for a limited period, Presale price will be 300 ROSE. 
                    </T>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'linear-gradient(to left,#c31432,#240b36)', color: '#FFF' }}
                    // date="Phase 05"
                
                    iconStyle={{ background: '#000000', color: '#fff' }}
                    icon={<IoIosPodium/>}
                >
                    <h3 className="vertical-timeline-element-title"></h3>
                    <h4 style={{fontSize:"1.4rem"}} className="vertical-timeline-element-subtitle">Milestone 7</h4>
                    <T>
                    Public sale: After the presale, the public sale will start at a higher price of 400 ROSE.
                    </T>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'linear-gradient(to left,#c31432,#240b36)', color: '#FFF' }}
                    // date="Phase 05"
                    iconStyle={{ background: '#000000', color: '#fff' }}
                    icon={<IoIosPodium/>}
                >
                    <h3 className="vertical-timeline-element-title"></h3>
                    <h4 style={{fontSize:"1.4rem"}} className="vertical-timeline-element-subtitle">Milestone 8</h4>
                    <T>
                    Partnership: Collaborations and Partnerships 
                    </T>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'linear-gradient(to left,#c31432,#240b36)', color: '#FFF' }}
                    // date="Phase 05"
                    iconStyle={{ background: '#000000', color: '#fff' }}
                    icon={<IoIosPodium/>}
                >
                    <h3 className="vertical-timeline-element-title"></h3>
                    <h4 style={{fontSize:"1.4rem"}} className="vertical-timeline-element-subtitle">Milestone 9</h4>
                    <T>
                    Giveaways
                    </T>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'linear-gradient(to left,#c31432,#240b36)', color: '#FFF' }}
                    // date="Phase 05"
                    iconStyle={{ background: '#000000', color: '#fff' }}
                    icon={<IoIosPodium/>}
                >
                    <h3 className="vertical-timeline-element-title"></h3>
                    <h4 style={{fontSize:"1.4rem"}} className="vertical-timeline-element-subtitle">Milestone 10</h4>
                    <T>
                    Airdrop: Every week there will be airdrops of RIM tokens & special giveaways for OasisGorilla holders. The one with more NFTs will receive a larger airdrop. 
                    So, buy more OasisGorillas to earn more monthly passive incomes.
                    </T>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'linear-gradient(to left,#c31432,#240b36)', color: '#FFF' }}
                    // date="Phase 05"
                    iconStyle={{ background: '#000000', color: '#fff' }}
                    icon={<IoIosPodium/>}
                >
                    <h3 className="vertical-timeline-element-title"></h3>
                    <h4 style={{fontSize:"1.4rem"}} className="vertical-timeline-element-subtitle">Milestone 11</h4>
                    <T>
                    Marketplace listing
                    </T>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'linear-gradient(to left,#c31432,#240b36)', color: '#FFF' }}
                    // date="Phase 05"
                    iconStyle={{ background: '#000000', color: '#fff' }}
                    icon={<IoIosPodium/>}
                >
                    <h3 className="vertical-timeline-element-title"></h3>
                    <h4 style={{fontSize:"1.4rem"}} className="vertical-timeline-element-subtitle">Milestone 12</h4>
                    <T>
                    Improved UX
                    </T>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'linear-gradient(to left,#c31432,#240b36)', color: '#FFF' }}
                    // date="Phase 05"
                    iconStyle={{ background: '#000000', color: '#fff' }}
                    icon={<IoIosPodium/>}
                >
                    <h3 className="vertical-timeline-element-title"></h3>
                    <h4 style={{fontSize:"1.4rem"}} className="vertical-timeline-element-subtitle">Milestone 13</h4>
                    <T>
                    NFT Marketplace:  Launch of the NFT Marketplace by the OasisGorillas team on the Oasis Emerald Network.
                    </T>
                </VerticalTimelineElement>


                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ display:'none', background: 'linear-gradient(to left,#c31432,#240b36)', color: '#FFF' }}
                    // date="Phase 05"
                    iconStyle={{ background: '	#000000', color: '#000000' }}
                    icon={<Icon>
                        <img src={logo} alt="" style={{height:"100%",width:"100%"}}/>
                    </Icon>}
                >
                    <h3 className="vertical-timeline-element-title"></h3>
                    <h4 style={{fontSize:"1.4rem"}} className="vertical-timeline-element-subtitle">Milestone 5</h4>
                
                </VerticalTimelineElement>

              </VerticalTimeline>


        </Sec>
    )
}

export default Roadmap2
