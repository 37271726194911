import React from 'react';
import { bool } from 'prop-types';
import { StyledMenu } from './Menu.styled';
import styled from 'styled-components';
import { Link2 } from '../../NavBar/NavBar';

const MenuItems = styled.div`
  display: grid;
  place-items: center;
 
`

const Link1 = styled.a`
    display: flex;
    margin: 0 2rem;
    align-items: center;
    text-decoration: none;
    color: #FFF;
    position: absolute;
    left: 2rem;
    top: -0.3rem;
`;

const Right = styled.div`
    display: flex;
    flex-direction: column;
    
    align-items: center;
    position: absolute;
    bottom: 2rem;
`;

const Img = styled.img`
    width: 3rem;
`
const Flex = styled.div`
    display: flex;
    justify-content: space-evenly;
    width: 70%;
`;

const T = styled.p`
    color: #FFF;
    font-weight: 500;
    font-size: 16px;
    margin-top: 1rem;
    @media only screen and (max-width: 600px) {
        font-weight: 510;
        font-size: 1rem;
      }
`;

const H = styled.h1`

@font-face {
  font-family: myFirstFont;
  src: url(./inter.ttf);
}

margin: 0.8rem 0 0 0;

    font-family: myFirstFont !important;
  
`;

const Menu = ({ open,setOpen, ...props }) => {
  
  const isHidden = open ? true : false;
  const tabIndex = isHidden ? 0 : -1;

  return (
    <StyledMenu open={open} aria-hidden={!isHidden} {...props}>
      <Link1 href="/" ><H>Oasis Gorillas</H></Link1>

      <MenuItems>
        <Link2 to="home" spy={true} smooth={true} onClick={()=> setOpen(false)}>
           <H>Home</H> 
        </Link2>
        <Link2 to="roadmap" spy={true} smooth={true} onClick={()=> setOpen(false)} >
          <H>Roadmap</H>
        </Link2>
        <Link2 to="faq" spy={true} smooth={true} offset={-80}  onClick={()=> setOpen(false)}>
          <H>FAQ</H>
        </Link2>
        <a style={{padding:"0"}} href="https://rimswap.app/farms" onClick={()=> setOpen(false)} >
         <H>Farms</H> 
        </a>
        <a style={{padding:"0"}} href="https://dex.rimswap.app/#/swap" onClick={()=> setOpen(false)}>
         <H>Swap</H> 
        </a>
      </MenuItems>
    
      <Right>
        {/* <Flex>
          <Img src={twitter} alt="" />
          <Img src={opensea} alt="" />
        </Flex> */}
        <T>RimSwap 2022 All Right Reserved.</T>
      </Right>

    </StyledMenu>
  )
}

Menu.propTypes = {
  open: bool.isRequired,
}

export default Menu;
