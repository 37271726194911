import React from 'react'
import styled from 'styled-components';
import { data } from '../config/config'
import InvisibleMCard from '../../components/Card/Card'
import InvisibleCard from '../../components/Card/InvisibleCard'
import { data2 } from '../config/config2'

const Page = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    width: 100%;

    background:  linear-gradient(to right, #c31432, #240b36);
    
    padding: 8rem 0 3rem 0;
    @media only screen and (max-width: 768px) {
        min-height: 100vh;
        width: 100%;
        background-position: center center;
        padding: 2rem 0.5rem 2rem 0.5rem;
        background-size: auto 100%;
        flex-direction: column;
    }
`;

const Grid = styled.div`
    display: grid;
    width: 100%;
    height: auto;
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    place-items: center;
    position: relative;
    z-index: 4;

    @media only screen and (min-width: 1100px) {
        width: 100%;
        // grid-template-columns: repeat(4, minmax(20%, 1fr));
        grid-gap: 10px;
    }
    @media only screen and (max-width: 768px) {
        display: none;
    }
`;

const MobileGrid = styled.div`
    display: none;
    @media only screen and (max-width: 768px) {
        display: grid;
        width: 100%;
        height: auto;
        grid-template-columns: repeat(auto-fit, minmax(50%, 1fr)) ;
        place-items: center;
        position: relative;
        z-index: 4;
       
    }
    
`;

const H = styled.h2`
    color: #FFF;
    font-weight: bolder;
    font-size: 3rem;
    text-align: left;
    @media only screen and (max-width: 768px) {
        font-weight: 550;
        font-size: 1.2rem;
        text-align: center;
      }
`;

const Community = () => {
  return (
    <Page>
        <H>Join Community</H>
        <Grid>
             
                {data?.map(e => (
                    <InvisibleMCard
                        img={e.img}
                        name={e.name}
                        link={e.link}
                        whileHover={{ scale: 1.06,boxShadow:"0 0 25px #ff7b00"}}
                    />
                ))}
            </Grid>

            <MobileGrid>
                {data?.map(e => (
                    <InvisibleCard
                        img={e.img}
                        name={e.name}
                        link={e.link}
                    />
                ))}
            </MobileGrid>
    </Page>
  )
}

export default Community