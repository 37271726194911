import React, { useState, useEffect } from 'react'
import './Page2.css'
import styled from 'styled-components'
import bg from './bg.png'
import { FcPlus } from 'react-icons/fc';
import { FaMinusCircle } from 'react-icons/fa';
import { motion } from 'framer-motion';
import mint from './mint.png'
import { defaultdata } from '../config/config'
import { defaultdata2 } from '../config/config2'
import DefaultCard from '../../components/Card/DefaultCard'
import DefaultCard2 from '../../components/Card/DefaultCard2'
import Brain from './brain.svg'
import { useSelector } from 'react-redux';
import { useWallet } from '@binance-chain/bsc-use-wallet';

const Page = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    width: 100%;

    background:  linear-gradient(to right, #c31432, #240b36);
    
    padding: 8rem 0 3rem 0;
    @media only screen and (max-width: 768px) {
        min-height: 100vh;
        width: 100%;
        background-position: center center;
        padding: 2rem 0.5rem 2rem 0.5rem;
        background-size: auto 100%;
        flex-direction: column;
    }
`;


const Width = styled.div`
      width: 1300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @media only screen and (max-width: 1400px) {
        width: 95%;
    
      }
   
    @media only screen and (max-width: 1100px) {
        flex-direction: column;
    }
    @media only screen and (max-width: 768px) {
        margin: 0 0 0 ;
      }

`

const H = styled.h2`
    color: #FFF;
    font-weight: bolder;
    font-size: 3rem;
    text-align: center;
    @media only screen and (max-width: 768px) {
        font-weight: bolder;
        font-size: 1.2rem;
        text-align: center;
      }
`;



const T = styled.p`
    color: #FFF;
    font-weight: 550;
    font-size: 1.5rem;
    margin: 1.5rem 0 0 0;
    @media only screen and (max-width: 768px) {
        font-weight: 450;
        font-size: 1rem;
      }
`;

const Grid = styled.div`
    display: grid;
    width: 80%;
    height: auto;
    grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
    place-items: center;
    position: relative;
    z-index: 4;
    grid-gap: 30px;
    @media only screen and (min-width: 1100px) {
        width: 100%;
        grid-template-columns: repeat(4, minmax(20%, 1fr));
        grid-gap: 10px;
    }
    @media only screen and (max-width: 768px) {
        display: none;
    }
`;

const MobileGrid = styled.div`
    display: none;
    @media only screen and (max-width: 768px) {
        display: grid;
        width: 100%;
        height: auto;
        grid-template-columns: repeat(auto-fit, minmax(50%, 1fr)) ;
        place-items: center;
        position: relative;
        z-index: 4;
       
    }
    
`;


const Page2 = () => {
    const mydata = useSelector(state => state.mydata);
    const account1 = useSelector(state => state.account);
    const contract = useSelector(state => state.contract);
    const [priceForNft, setpriceForNft] = useState(0);
    const [value, setvalue] = useState(0);
    const { account, connect } = useWallet();

    const handleMint = async () => {
        await contract.methods.mint(value).send({
            from: account,
            value: priceForNft
        })
    }

    const [noOfNFT, setNoOfNFT] = useState(null);

    useEffect(() => {
        connect();
    }, [])

    return (
        <Page >
            <Width>
                <div>
                    <H>
                        Oasis Gorillas
                    </H>
                    <T >OasisGorillas are the first NFT Collation of Gorillas on the Oasis Network and it's developed by the team of RIMSwap decentralized exchange, consisting of
                        10,000 unique collectible Bullish Gorillas and features over 50 unique attributes.</T>

                    <T >OasisGorillas NFT is a part of the RIMSwap DEX ecosystem. So, every week OasisGorillas holders will get a free airdrop of RIM tokens. RIM token is the native token of RIMSwap DEX. Every week,
                        30% of the AMA trading fees will be distributed to OasisGorilla NFT holders.
                    </T>
                </div>
                <Grid>

                    {defaultdata?.map(e => (
                        <DefaultCard
                            img={e.img}
                            name={e.name}
                            L1={e.L1}
                            L2={e.L2}
                            L3={e.L3}
                            L4={e.L4}
                        />
                    ))}
                </Grid>

                <MobileGrid>
                    {defaultdata2?.map(e => (
                        <DefaultCard2
                            img={e.img}
                            name={e.name}
                            L1={e.L1}
                            L2={e.L2}
                            L3={e.L3}
                            L4={e.L4}
                        />
                    ))}
                </MobileGrid>
            </Width>
        </Page>
    )
}

export default Page2
