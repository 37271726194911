import React from 'react'
import './App.css'
import Home from './page/Home/Home'
import NavBar from './components/NavBar/NavBar';
import Page2 from './page/SecondPage/Page2';
import Page3 from './page/ThirdPage/Page3';
import Page4 from './page/ForthPage/Page4';
import Footer from './page/Footer/Footer';
import Roadmap2 from './page/Roadmap/Roadmap';
import BlockchainProvider from './BlockchainProvider/index.js';
import Faq from './page/Faq/Faq';
import Community from './page/Community/Community';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

const App = () => {
  return (
    <>
      <NavBar />
      <BlockchainProvider />

      <Home />
      <Page2 />
      <Roadmap2 />
      <Faq />
      
      <Community />
      <Footer />
    </>
  )
}

export default App
