import React,{useState,useEffect} from 'react'
import './Home.css'
import styled from 'styled-components'
import bg from './gif2.gif'
import mbg from './bg2.png'
import right from './right.gif'
import img1 from './9.png'
import img2 from './9.png'
import img3 from './9.png'
import { motion } from 'framer-motion'
import Title from '../../components/NavBar/logoTextLight.png'
import Scroll from './scroll.svg'
import "react-alice-carousel/lib/alice-carousel.css";
import AliceCarousel from 'react-alice-carousel';
import { useSelector } from 'react-redux';
import { useWallet } from '@binance-chain/bsc-use-wallet';
import { FcPlus } from 'react-icons/fc';
import { FaMinusCircle } from 'react-icons/fa';

const HomePage = styled.section`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    min-height: 100vh;
    width: 100%;
    background-image: url(${bg});
    background-size: auto 100%;
    background-position: center center;
    background-repeat: no-repeat;
   

    @media only screen and (max-width: 998px) {
     
        flex-direction: column;
        padding: 10rem 0 0 0;
    }
    
    @media only screen and (max-width: 768px) {
     
        flex-direction: column;
        padding:5rem 0 0 0;
    }
`;

const Left = styled.div`
        display: flex;
        flex-direction: column;
        height: auto;
        width: 45%;

        @media only screen and (max-width: 1500px) {
            width: 50%;
          }
  
@media only screen and (max-width: 768px) {
        padding: 2rem;
        width: 100%;
        position: relative;
        left: 0;
       
    }
    @media only screen and (max-width: 600px) {
        padding: 2rem;
        width: 100%;
        position: relative;
        left: 0;
        text-align: center;
        align-items: center;
    }
`;

const Right = styled(motion.div)`
    width: 28rem; 
    height: 30rem;
    background-image: url(${right});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: block;
    transition:  0.5s linear;
    display: flex;
        flex-direction: column;
        align-items: center;

 @media only screen and (max-width: 1500px) {
    width: 22rem; 
    height: 25rem;
      }
    
      @media only screen and (max-width: 998px) {
     
  
        margin: 5rem 0 0 0;
    }
    @media only screen and (max-width: 768px) {
        width: 85%;
        height: 25rem;
        position: relative;
        right: 0;
       
    }
    
`;

const BigCard = styled.div`
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(136, 8, 8,0.7);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    padding: 4rem 2rem;
    border-radius: 2rem;
    // margin-bottom: 100px;

    @media only screen and (max-width: 1500px) {
     
        padding: 2rem;
    }

    @media only screen and (max-width: 1000px) {
        width: 95%;
        padding: 2rem 2rem;
    }
    @media only screen and (max-width: 700px) {
        width: 95%;
        padding: 2rem 1rem;
    }
`

const ScrollContainer = styled(motion.div)`
      width: 1rem;
      height: 15rem;
      position: absolute;
      bottom: 3rem ;
      left: 4rem;
      display: block;

      @media only screen and (max-width: 768px) {
        bottom: 1rem ;
        left: 1rem;
        display: none;
      }
`
const Mobile = styled.div`
    display: none;
    @media only screen and (max-width: 768px) {
        display: flex;
        width: 100%;
        height: auto;
      }
`;

const H = styled.h2`
    color: #FFF;
    font-weight: bolder;
    font-size: 3rem;
    @media only screen and (max-width: 1500px) {
        font-size: 2rem;
      }
    @media only screen and (max-width: 768px) {
        font-weight: 550;
        font-size: 1.2rem;
        text-align: center;
      }
`;

const Button = styled(motion.div)`
    background-color: #deba27;
    color:#013c8a;
    height: 3rem;
    border: none;
    border-bottom: 2px solid #E5E5E5;
    border-radius: 0.7rem;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0 1rem;
    display: grid;
    place-items: center;
    padding-top: 0.5rem; 
    width: auto;
    @media only screen and (max-width: 768px) {
      height: 2.7rem;
 }
`;


const Hc = styled.h2`
    color: #FFF;
    font-weight: bolder;
    font-size: 3rem;
    @media only screen and (max-width: 768px) {
        font-weight: 550;
        font-size: 1.2rem;
        text-align: center;
      }
`;


const Hu = styled.h2`
    color: #FFF;
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 3rem;
    @media only screen and (max-width: 768px) {
        font-weight: bold;
        font-size: 1.3rem;
        text-align: center;
      }
`;

const Tc = styled.p`
    color: #FFF;
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 2rem;
    @media only screen and (max-width: 768px) {
        font-weight: bold;
        font-size: 1.5rem;
      }
`;

const Tu = styled.p`
    color: #fbcf2c;
    margin-bottom: 1rem ;
    font-weight: bold;
    font-size: 20px;
    @media only screen and (max-width: 768px) {
        font-weight: bold;
        font-size: 1rem;
      }
`;

const Td = styled.p`
    color: #fbcf2c;
    margin-bottom: 1rem ;
    font-weight: bold;
    font-size: 20px;
    @media only screen and (max-width: 768px) {
        font-weight: bold;
        font-size: 1rem;
      }
`;

const SmBox = styled.div`
      margin: 0 2rem 0 0;
      width: 14rem;
      @media only screen and (max-width: 768px) {
        margin: 2rem 0 0 0;
        width: 100%;
      }
`;

const Upper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #FFF;
    width: 100%;
    border-radius: 1rem 1rem 0 0;
    padding: 0 0.5rem;
    height: 4rem;
    @media only screen and (max-width: 768px) {
        width: 100%;
        height: auto;
        justify-content: center;
        height: 4rem;
      }
`;

const Lower = styled.div`
    display: grid;
    place-items: center;
    background-color: #c6250a;
    width: 100%;
    border-radius: 0 0 1rem 1rem ;
    @media only screen and (max-width: 768px) { 
        width: 100%;
        height: auto;
      }
`;

const BigBox = styled.div`
    min-width: 15rem;
    min-height: 8rem;
    border: 2px solid #013C8A;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 2rem;
    margin-top: 0rem;
    @media only screen and (max-width: 768px) {
        flex-direction: column;
        min-height: auto;
        margin-top: 2rem;
        padding: 2rem 0;
        width: 100%;
    }
`;

const Divider = styled.div`
      height: 6rem;
      width: 3px;
      background-color: #013C8A;
      margin: 0 1rem;
      @media only screen and (max-width: 768px) {
        margin: 2rem 0 0 0;
        flex-direction: column;
        width: 100%;
        height: 2px;
    }
`
const LeftC = styled.div`
     margin: 0 1rem 0 0;
     @media only screen and (max-width: 768px) {
        margin: 0 0 0 0;
        display: grid;
        place-items: center;
     }
`;

const RightC = styled.div`
      margin:0 0 0 1rem;
      @media only screen and (max-width: 768px) {
        margin: 2rem 0 0 0;
        display: grid;
        place-items: center;
      }
`;

const BoxContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin: 60px 0;
    @media only screen and (max-width: 768px) {
        flex-direction: column-reverse;
        margin: 0;
    }
`;

const ButtonBack = styled.div`
    background-color: #deba27;
    height: 3.4rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    border-radius: 0.7rem;
    min-width: 15rem;
    @media only screen and (max-width: 768px) {
      height: 3rem;
      margin-top: 2rem;
      width: 100%;
 }
`

const responsive = {
    0: { items: 1 },
    568: { items: 1 },
    768: { items: 1 },
    900: { items: 1 },
    1000: { items: 1 },
    1024: { items: 1 },

};

const Home = () => {

    const mydata = useSelector(state => state.mydata);
    const account1 = useSelector(state => state.account);
    const contract = useSelector(state => state.contract);
    const [priceForNft, setpriceForNft] = useState(0);
    const { account, connect } = useWallet();

    const handleMint = async () => {
        if(noOfNFT < 1){
            alert("Invalid No of NFT")
            return
        }
        if(priceForNft >0){
            await contract.methods.mint( noOfNFT).send({
                from: account,
                value: priceForNft
            })
        }
       
    }

    const [noOfNFT, setNoOfNFT] = useState(1);

    useEffect(()=>{
        if( mydata?.price){
            setpriceForNft(noOfNFT * mydata?.price || 0)
        }
    },[ mydata?.price])


    useEffect(()=>{
        setpriceForNft(noOfNFT * mydata?.price || 0)

    },[noOfNFT])
    useEffect(() => {
        connect();
    }, [])

    return (
        <HomePage id="home">
            <Left>
            
                <BigCard>
                {/* <H><span style={{ fontWeight: "bold" }}>7,420 </span> Rose Remaining</H> */}

                <BoxContainer>
                    <SmBox>
                        <Upper>
                            <FaMinusCircle className="iconL" onClick={() => {
                                if (noOfNFT > 0) {
                                    setNoOfNFT(noOfNFT - 1)
                                }
                            }} />

                            <input placeholder="No.s of NFT" value={noOfNFT} style={{ width: "70%", height: "60%", border: "none",fontSize:"1.5rem",paddingLeft:"0.5rem" }} onChange={(e) => { setpriceForNft(parseInt(e.target.value) * mydata?.price || 0); 
                                setNoOfNFT( parseInt( e.target.value)) }} type="number" />

                            <FcPlus className="iconR" onClick={() => {
                                if (noOfNFT < 20) {
                                    setNoOfNFT(noOfNFT + 1)
                                }
                            }}
                            />
                        </Upper>
                        <Lower>
                            <Tc style={{ margin: "1rem 0" }}>Max 20</Tc>
                        </Lower>
                    </SmBox>
                    <BigBox>
                        <LeftC>
                            <Tu >COST</Tu>
                            <Hu style={{ margin: "0" }} >{mydata?.price / 10 ** 18 || 300} ROSE</Hu>
                        </LeftC>
                        <Divider />
                        <RightC>
                            <Td>TOTAL COST</Td>
                            <Hu style={{ margin: " 0" }}>{priceForNft / 10 ** 18} + GAS</Hu>
                        </RightC>
                    </BigBox>
                </BoxContainer>

                {account ? 
                    <ButtonBack>
                        <Button
                            disabled={!mydata || !mydata?.price || mydata?.price<1}
                            onClick={() => handleMint()}
                        >
                            MINT
                        </Button>
                    </ButtonBack> :
                    <ButtonBack>
                        <Button onClick={()=> connect()}>
                            Connect
                        </Button> 
                    </ButtonBack>
                    }
            </BigCard>
            </Left>
            <Right
                initial={{ x: 0, y:0}}
                animate={{ x: 0, y: -15 }}
                transition={{ duration: 1, repeat: Infinity ,repeatType: "reverse",
               
                }}
            />

            <ScrollContainer
                initial={{ x: 0, y:0}}
                animate={{ x: 0, y: -15 }}
                transition={{ duration: 1, repeat: Infinity ,repeatType: "reverse",
               
                }}
            >
                <img src={Scroll} alt="" style={{width:"100%",height:"100%",borderRadius:'1rem'}} />
            </ScrollContainer>

            {/* <Mobile>
            <AliceCarousel
                // paddingLeft={12}
                autoPlay={true}
                infinite={true}
                animationDuration={1700}
                disableButtonsControls={true}
                disableDotsControls={true}
                responsive={responsive}
            >
                <img src={img1} style={{margin:'0 auto'}}  className="yclass"/>
                <img src={img2} style={{margin:'0 auto'}}  className="tclass"/>
                <img src={img3} style={{margin:'0 auto'}}  className="yclass"/>
             </AliceCarousel>
            </Mobile> */}
            
                {/* <img src={right} alt="" style={{width:"100%",height:"100%"}} /> */}
            {/* </Right> */}
        </HomePage>
    )
}

export default Home
